/**
 * Set a minimalistic set of global rules.
 */
html {
	font-family: sans-serif;
	box-sizing: border-box;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	/* scroll-behavior: smooth; */
	scroll-behavior: auto;
}
@media screen and (prefers-reduced-motion: reduce) {
	html {
		scroll-behavior: auto;
	}
}

*,
*::before,
*::after {
	box-sizing: inherit;
	font-family: inherit;
	/* outline: none; */
}

body {
	font-size: 1rem;
	margin: 0;
	color: #000000;
	background-color: #fff;
}

button,
a {
	text-decoration: none;
	color: inherit;
}

b,
strong {
	font-weight: bold;
}
em {
	font-style: italic;
}
